const home = document.querySelector(".home");
const loginBtn  = document.querySelector("#loginBtn");//glavni gumb u headeru
const loginm_link = document.querySelector("#loginm_link");
const loginm_linkk = document.querySelector("#loginm_linkk");
const logoutBtn = document.querySelector("#logoutBtn"); //glavni gum u headeru
const logoutm_link = document.querySelector("#logoutm_link");
const logoutm_linkk = document.querySelector("#logoutm_linkk");
const formContainer = document.querySelector(".form_container");
const formOpenBtn = document.querySelector("#loginBtn");
const formCloseBtn = document.querySelector(".form_close");
const formOpenlink = document.querySelector("#loginm_link");
const formCloselink = document.querySelector(".form_close");
const signupform = document.querySelector("#signup_form_link");
const loginform = document.querySelector("#login_form_link");
const pwShowHide = document.querySelectorAll(".pw-hide");
const forgotPasswordLink = document.querySelector("#forgot-password");
const forgotPasswordForm = document.querySelector(".forgot_password_form");
const resetPasswordForm = document.querySelector(".reset_password_form");
const forgotPasswordBtn = document.querySelector("#reset-password-btn");
const resetPasswordBtn = document.querySelector("#set-new-password-btn");
const connectWalletBtn = document.querySelector("#connectWallet");
const connectwalletm_link=document.querySelector("#connectwalletm_link");
const connectwalletm_linkk=document.querySelector("#connectwalletm_linkk");
const loginFormm = document.querySelector('#login_formm');
const signupFormm = document.querySelector('#signup_formm');
const loginNowBtn = document.querySelector('#login_now');
const winnersNavItem = document.getElementById('#winnersNavItem');
const contactNavItem = document.getElementById('#contactNavItem');
const contactSection = document.getElementById('#contact-section');
const aboutNavItem = document.getElementById('#aboutNavItem');
const aboutSection = document.getElementById('#about-section');
const addressElement = document.getElementById('crypto-address');
const timerElement = document.getElementById('timer');
const totalCollectedElement = document.getElementById('total-collected');
const ticketButtons = document.querySelectorAll('.ticket-button');
console.log('Selected ticket buttons:', ticketButtons);
import io  from 'socket.io-client';
import './style.css'; //izaziva gresku jer ne funkcionira lokalno ali na buildanoj verziji da jer webpack to obradi 
import '../node_modules/core-js/stable/index.js'
import '../node_modules/regenerator-runtime/runtime.js';
import { v4 as uuidv4 } from 'uuid';

// Polyfill for crypto.randomUUID
if (typeof crypto !== 'undefined' && typeof crypto.randomUUID !== 'function') {
  crypto.randomUUID = function() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  };
}

const generateUUID = () => {
    if (typeof crypto !== 'undefined' && crypto.randomUUID) {
        return crypto.randomUUID();
    } else {
        return uuidv4();  // fallback to uuidv4 if crypto.randomUUID is not supported
    }
};

const myUUID = generateUUID();
console.log('Generated UUID on the client side:', myUUID);


// Možeš koristiti UUID u aplikaciji kao što je dodavanje jedinstvenih ID-eva HTML elementima
// document.getElementById('someElement').id = myUUID;

/*********PRIKAZ TOAST ALERT START*************/
function showToast(message, duration = 3000) {
    const container = document.getElementById('toast-container');
    const toast = document.createElement('div');
    toast.className = 'toast';
    toast.innerText = message;
    container.appendChild(toast);

    setTimeout(() => {
        toast.remove();
    }, duration);
}
/*********PRIKAZ TOAST ALERT END*************/

/************MODAL START****************/
function showModal(message) {
    return new Promise((resolve) => {
        const modal = document.getElementById('modal');
        const messageEl = document.getElementById('modal-message');
        const confirmBtn = document.getElementById('confirm-btn');
        const cancelBtn = document.getElementById('cancel-btn');

        messageEl.innerText = message;
        modal.style.display = 'block';

        confirmBtn.onclick = () => {
            modal.style.display = 'none';
            resolve(true);
        };

        cancelBtn.onclick = () => {
            modal.style.display = 'none';
            resolve(false);
        };
    });
}
/************MODAL END****************/

/************MODAL2 START****************/
function showModal2(message) {
    return new Promise((resolve) => {
        const modal = document.getElementById('modal_2');
        const messageEl = document.getElementById('modal-message_2');
        const cancelBtn = document.getElementById('cancel-btn_2');

        messageEl.innerText = message;
        modal.style.display = 'block';

        cancelBtn.onclick = () => {
            modal.style.display = 'none';
            resolve(false);
        };
    });
}
/************MODAL2 END****************/

    //**********progress bar************//
        
    //boja loga
    document.querySelectorAll('.nav_logo span').forEach(span => {
        span.addEventListener('mouseover', () => {
        span.style.color = '#FFD700'; // Change this to your desired hover color
        });
        span.addEventListener('mouseout', () => {
        span.style.color = ''; // Reset to original color
        });
    });

    // progressbar
    const progress = document.getElementById('progressbar');
    const totalHeight = document.body.scrollHeight - window.innerHeight;
    window.onscroll = function() {
        let progressHeight = (window.pageYOffset / totalHeight) * 100; // Corrected calculation
        progress.style.height = progressHeight + "%";
    }


  

    /****HAMBURGER MENU****/

    document.addEventListener('DOMContentLoaded', function() {
        const hamburger = document.getElementById('hamburger');
        const mobileMenu = document.getElementById('mobileMenu');
        
        hamburger.addEventListener('click', function() {
          hamburger.classList.toggle('active');
          mobileMenu.classList.toggle('active');
        });
      });  

      /***MOBILE MENU START***/
      // Prilikom uspješne prijave
     /* function handleLoginSuccess() {
        // Pronađi elemente
        const connectWalletLink = document.getElementById('connectwalletm_link');
        const logoutLink = document.getElementById('logoutm_link');
    
        // Provjeri postoje li elementi prije izmjena
        if (connectWalletLink && logoutLink) {
            // Ukloni klasu "hidden"
            connectWalletLink.classList.remove('hidden');
            logoutLink.classList.remove('hidden');
        } else {
            console.error("Elementi 'connectwalletm_link' ili 'logoutm_link' nisu pronađeni.");
        }
            }
    

        // Prikazivanje prilikom pokretanja aplikacije na osnovu login statusa
        document.addEventListener('DOMContentLoaded', () => {
            const menu = document.querySelector('.mobile_menu');
            if (menu) {
                console.log("Mobile menu je učitan.");
                const isLoggedIn = true; // Zamijenite provjerom statusa
                if (isLoggedIn) {
                    handleLoginSuccess();
                }
            } else {
                console.error("Mobile menu nije pronađen.");
            }
        });*/

       /***MOBILE MENU END***/
     

// Funkcija za zatvaranje forme prilikom klika izvan nje
    function closeFormOnOutsideClick(formSelector) {
        document.addEventListener('click', function(event) {
            const formElement = document.querySelector(formSelector);
            const formContainer = document.querySelector('.form_container');

            // Provjeri da li je kliknut izvan forme i izvan container-a
            if (formElement && !formElement.contains(event.target) && !formContainer.contains(event.target) && !event.target.matches('.form_container')) {
                formElement.style.display = 'none';
            }
        });
    }
    

    document.getElementById('signup_form_link').addEventListener('click', function(event) {
        event.preventDefault(); // Sprečava zadano ponašanje linka
        document.querySelector('.signup_form').style.display = 'block'; // Prikazuje SignUp formu
        document.querySelector('.login_form').style.display = 'none'; // Sakriva Login formu
    });
    
    document.getElementById('login_form_link').addEventListener('click', function(event) {
        event.preventDefault(); // Sprečava zadano ponašanje linka
        document.querySelector('.login_form').style.display = 'block'; // Prikazuje Login formu
        document.querySelector('.signup_form').style.display = 'none'; // Sakriva SignUp formu
    });
    
    


        /*****QR CODE CONTACT SECTION*****/

        //document.querySelector('.qr_code').addEventListener('mouseover', () => {
           // alert('Scan this QR code to connect with us on Telegram!');
          //});


     //********LOGIRANJE**********/

     // Function to update UI elements based on login status
    function updateUIOnLoginStatus(isLoggedIn) {
        if (isLoggedIn) {
            loginBtn.style.display = 'none';
            loginm_linkk.style.display='none'
            logoutBtn.style.display = 'inline-block';
            logoutm_linkk.style.display = 'block';
            //document.getElementById('logoutm_linkk').classList.remove('hidden');
            connectWalletBtn.style.display='inline-block';
            connectwalletm_linkk.style.display='block';
            //document.getElementById('connectwalletm_linkk').classList.remove('hidden');

        } else {
            loginBtn.style.display = 'inline-block';
            loginm_linkk.style.display='block'
            logoutBtn.style.display = 'none';
            logoutm_linkk.style.display = 'none';
            //document.getElementById('logoutm_linkk').classList.add('hidden');
            connectWalletBtn.style.display='none';
            connectwalletm_linkk.style.display='none';
            //document.getElementById('connectwalletm_linkk').classList.add('hidden');

        }
    }
        

   

    // On page load, check if user is logged in and update UI
    window.onload = async function() {
        const token = getTokenFromCookie();
        if (token) {
            updateUIOnLoginStatus(true); // User is logged in
            //handleLoginSuccess(true);
        } else {
            updateUIOnLoginStatus(false); // User is not logged in
           // handleLoginSuccess(false);
        }
    };

    /*desktop version*/
    logoutBtn.addEventListener("click", async () => {
        try {
            const token = getTokenFromCookie();
    
            // Poziv backendu za odjavu
            const response = await fetch('http://localhost:3000/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log('Odgovor s backend-a za /logout:', response); 
            console.log('Status:', response.status);
            console.log('Content-Type:', response.headers.get('content-type'));

            if (response.status === 200) {
                // Ukloni token iz kolačića
                deleteTokenFromCookie();
    
                // Ukloni token iz crne liste
                const blacklistResponse = await fetch('http://localhost:3000/blacklist/remove', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ token: token })
                });
    
                if (blacklistResponse.ok) {
                    updateUIOnLoginStatus(false); // Ažuriraj UI nakon uspješne odjave
                    //handleLoginSuccess(false);
                    alert('Uspješno ste se odjavili');
                    alert('To completely disconnect, open the MetaMask extension and click "Disconnect".');
                    // Ovdje možete dodati redirekciju ili ažuriranje stranice ako je potrebno
                } else {
                    handleBlacklistError(blacklistResponse);
                    console.log(blacklistResponse)
                }
            } else {
                handleLogoutError(response);
            }
        } catch (error) {
            console.error('Greška:', error);
            console.log('Greška pri odjavi');
        }
    });
    
    /*mobile menu version*/
    logoutm_link.addEventListener("click", async () => {
        try {
            const token = getTokenFromCookie();
    
            // Poziv backendu za odjavu
            const response = await fetch('http://localhost:3000/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log('Odgovor s backend-a za /logout:', response); 
            console.log('Status:', response.status);
            console.log('Content-Type:', response.headers.get('content-type'));

            if (response.status === 200) {
                // Ukloni token iz kolačića
                deleteTokenFromCookie();
    
                // Ukloni token iz crne liste
                const blacklistResponse = await fetch('http://localhost:3000/blacklist/remove', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ token: token })
                });
    
                if (blacklistResponse.ok) {
                    updateUIOnLoginStatus(false); // Ažuriraj UI nakon uspješne odjave
                    //handleLoginSuccess(false);
                    alert('Uspješno ste se odjavili');
                    alert('To completely disconnect, open the MetaMask extension and click "Disconnect".');
                    // Ovdje možete dodati redirekciju ili ažuriranje stranice ako je potrebno
                } else {
                    handleBlacklistError(blacklistResponse);
                    console.log(blacklistResponse)
                }
            } else {
                handleLogoutError(response);
            }
        } catch (error) {
            console.error('Greška:', error);
            console.log('Greška pri odjavi');
        }
    });

    function deleteTokenFromCookie() {
        document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

        // Osvježavanje stranice
        location.reload();
    }

    // Postavite timer na 1 sat (60 minuta)
    setTimeout(deleteTokenFromCookie, 60 * 60 * 1000);
    
    
    function handleLogoutError(response) {
        if (response.headers.get('content-type') && response.headers.get('content-type').includes('application/json')) {
            response.json().then(data => {
                alert('Greška: ' + data.message);
            }).catch(err => {
                console.error('Unexpected response:', err);
                alert('Greška: Neočekivan odgovor od servera');
            });
        } else {
            response.text().then(errorText => {
                console.error('Unexpected response:', errorText);
                alert('Greška: Neočekivan odgovor od servera');
            }).catch(err => {
                console.error('Unexpected response:', err);
                alert('Greška: Neočekivan odgovor od servera');
            });
        }
    }
    


    // Function to check if user is logged in
    /*function isLoggedIn() {
        const token = getTokenFromCookie();
        return token !== null;
    }*/

    // Funkcija za provjeru je li korisnik ulogiran
    function isLoggedIn() {
        const token = getTokenFromCookie();
        console.log('Token from cookie:', token);
        return token !== null && token !== '';
    }



    // Funkcija za dohvaćanje JWT tokena iz cookiea
    function getTokenFromCookie() {
        const name = 'jwt' + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return null;
    }

    // Otvaranje i zatvaranje obrasca
    formOpenBtn.addEventListener("click", () => home.classList.add("show"));
    formCloseBtn.addEventListener("click", () => home.classList.remove("show"));

    // Otvaranje i zatvaranje m-obrasca
    formOpenlink.addEventListener("click", () => home.classList.add("show"));
    formCloselink.addEventListener("click", () => home.classList.remove("show"));

    // Funkcionalnost prikazivanja/skrivanja lozinke
    pwShowHide.forEach((icon) => {
        icon.addEventListener("click", () => {
            const getPwInput = icon.parentElement.querySelector("input");
            getPwInput.type = getPwInput.type === "password" ? "text" : "password";
            icon.classList.toggle("uil-eye-slash");
            icon.classList.toggle("uil-eye");
        });
    });

    //Prikazivanje obrasca za registraciju
    signupform.addEventListener("click", (e) => {
        e.preventDefault();
        formContainer.classList.add("active");
    });  //radi

     //Prikazivanje obrasca za logiranje
     loginform.addEventListener("click", (e) => {
        e.preventDefault();
        formContainer.classList.remove("active");
    }); //radi



    document.addEventListener("DOMContentLoaded", () => {
        const loginLink = document.querySelector("#loginm_link"); // Login link
        const loginBtn = document.querySelector("#loginBtn");
        loginBtn.addEventListener("click", (e) => {
            e.preventDefault();
            console.log("Login button clicked");
    
            if (isLoggedIn()) {
                logoutUser(); // Ako je prijavljen, izvrši odjavu
            } else {
                formContainer.classList.remove("active"); // Prikaži obrazac za prijavu
            }
        });
        loginLink.addEventListener("click", (e) => {
            e.preventDefault();
            console.log("Login button clicked");
    
            if (isLoggedIn()) {
                logoutUser(); // Ako je prijavljen, izvrši odjavu
            } else {
                formContainer.classList.remove("active"); // Prikaži obrazac za prijavu
            }
        });
    });
    


    // Slanje obrasca za registraciju
    document.querySelector(".signup_form").addEventListener("submit", async function (event) {
        event.preventDefault();
        const email = this.querySelector('input[type="email"]').value;
        const password = this.querySelectorAll('input[type="password"]')[0].value;
        try {
            const response = await fetch('http://localhost:3000/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username: email, password: password })
            });
            if (response.ok) {
                const data = await response.json();
                alert('Success: ' + data.message);
            } else {
                const errorData = await response.json();
                alert('Error: ' + errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error registering user');
        }
    });

    // Prikazivanje obrasca za zaboravljenu lozinku
    forgotPasswordLink.addEventListener("click", (e) => {
        e.preventDefault();
        formContainer.classList.add("active-forgot");
        formContainer.classList.remove("active");
    });

    // Slanje obrasca za zaboravljenu lozinku
    forgotPasswordForm.querySelector("form").addEventListener("submit", async function (event) {
        event.preventDefault();
        const email = this.querySelector('input[type="email"]').value;
        try {
            const response = await fetch('http://localhost:3000/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: email })
            });
            if (response.ok) {
                const data = await response.json();
                alert('Success: ' + data.message);
            } else {
                const errorData = await response.json();
                alert('Error: ' + errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error sending password reset email');
        }
    });

    // Slanje obrasca za resetiranje lozinke
    resetPasswordBtn.addEventListener("click", async function (event) {
        event.preventDefault();
        const token = new URLSearchParams(window.location.search).get('token');
        const newPassword = resetPasswordForm.querySelectorAll('input[type="password"]')[0].value;
        const confirmPassword = resetPasswordForm.querySelectorAll('input[type="password"]')[1].value;


        if (newPassword !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        try {
            const response = await fetch('http://localhost:3000/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token: token, newPassword: newPassword })
            });
            if (response.ok) {
                const data = await response.json();
                alert('Success: ' + data.message);
                // Redirect to login page or perform other appropriate action
            } else {
                const errorData = await response.json();
                alert('Error: ' + errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error resetting password');
        }
    });

    document.addEventListener("DOMContentLoaded", () => {
        const loginFormm = document.querySelector("#login_form");
    
        loginFormm.addEventListener('submit', (event) => {
            event.preventDefault(); // Prevents page reload
    
            const email = document.querySelector('#email').value;
            const password = document.querySelector('#password').value;
    
            loginUser(email, password); // Call the login function
        });
    });
    
   

    //novo test
    loginNowBtn.addEventListener("click", async function (event) {
        event.preventDefault(); // Prevents form submission

        let emailInput = document.querySelector('#login_form input[type="email"]');
        let passwordInput = document.querySelector('#login_form input[type="password"]');
        let emailValue = emailInput.value.trim();
        let passwordValue = passwordInput.value.trim();

        let isValid = true;

        // Provjera e-maila (možeš dodati regex provjeru ako želiš)
        if (emailValue === '') {
            emailInput.parentElement.classList.add('error');
            isValid = false;
        } else {
            emailInput.parentElement.classList.remove('error');
        }

        // Provjera lozinke
        if (passwordValue === '') {
            passwordInput.parentElement.classList.add('error');
            isValid = false;
        } else {
            passwordInput.parentElement.classList.remove('error');
        }

        if (!isValid) {
            return; // Ako su podaci neispravni, ne pokreće se login funkcija
        }

        // Poziv login funkcije ako su podaci ispravni
        loginUser(emailValue, passwordValue);
    });


    
let loginBlockedUntil = localStorage.getItem('loginBlockedUntil') ? new Date(localStorage.getItem('loginBlockedUntil')) : null;

async function loginUser(username, password) {
    try {
        console.log('🔹 Sending login request with:', username, password);

        const now = new Date();

        if (loginBlockedUntil && new Date(loginBlockedUntil) > now) {
            const remainingTime = Math.ceil((new Date(loginBlockedUntil) - now) / 1000);
            const minutes = Math.floor(remainingTime / 60);
            const seconds = remainingTime % 60;

            showModal2(
                `Your account is still blocked. Please try again in ${minutes}:${seconds.toString().padStart(2, '0')} minutes.`
            );
            return;
        }

        const response = await fetch('http://localhost:3000/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password, localTime: now }),
        });

        const responseText = await response.text();
        console.log('🔹 Server response:', responseText);

        let emailInput = document.querySelector('#login_form input[type="email"]');
        let passwordInput = document.querySelector('#login_form input[type="password"]');

        if (response.ok) {
            const data = JSON.parse(responseText);
            console.log('✅ Login successful:', data);

            loginBlockedUntil = null;
            localStorage.removeItem('loginBlockedUntil');

            saveTokenToCookie(data.token);
            updateUIOnLoginStatus(true);
            //handleLoginSuccess(true);
            enableTicketButtons();
            formContainer.classList.remove("show");
            document.querySelector('.home').classList.remove("show");

            emailInput.parentElement.classList.remove('error');
            passwordInput.parentElement.classList.remove('error');
        } else {
            const errorData = JSON.parse(responseText);
            console.log('❌ Login failed. Server response:', errorData);

            if (errorData.remainingTime) {
                loginBlockedUntil = new Date(now.getTime() + errorData.remainingTime * 60000);
                localStorage.setItem('loginBlockedUntil', loginBlockedUntil);
                showModal2(`Account is blocked. Please try again in ${errorData.remainingTime} min!`);
                return;
            }

            emailInput.parentElement.classList.add('error');
            passwordInput.parentElement.classList.add('error');
           // showModal2('Error: ' + errorData.message);

            setTimeout(() => {
                emailInput.parentElement.classList.remove('error');
                passwordInput.parentElement.classList.remove('error');
            }, 600);
        }
    } catch (error) {
        console.error('🚨 Error in loginUser function:', error);
        showModal2("An unexpected error occurred. Please try again!");
    }
}
    
        
    // Kada korisnik klikne na polje, crveni border nestaje  Novo test login forma
    document.querySelectorAll('#login_form input').forEach(input => {
        input.addEventListener('input', function () {
            this.parentElement.classList.remove('error');
        });
    });
   


    // Function to save token to cookie
    function saveTokenToCookie(token) {
        document.cookie = `jwt=${token}; path=/;`;
    }

    // Funkcija za omogućavanje ticket gumba
    function enableTicketButtons() {
        const ticketButtons = document.querySelectorAll('.ticket-button');
        ticketButtons.forEach(button => {
            button.disabled = false;
            button.classList.remove('disabled-button');
        });
        console.log('Ticket buttons are now enabled.');
    }

    // Provjera i postavljanje gumba na temelju prisutnosti JWT tokena
    document.addEventListener('DOMContentLoaded', () => {
        const token = getTokenFromCookie();

        if (token) {
           // loginm_link.textContent = 'Logout';
            loginBtn.textContent = 'Logout'; // Ako je korisnik prijavljen
            connectWalletBtn.style.display = 'block'; // Prikazivanje gumba za povezivanje novčanika
           // connectwalletm_link.style.display='block';
        } else {
            //loginm_link.textContent = 'Login';
            loginBtn.textContent = 'Login'; // Ako korisnik nije prijavljen
            connectWalletBtn.style.display = 'none'; // Sakrivanje gumba za povezivanje novčanika
            //connectwalletm_link.style.display ='none';
        }
    });





    // Function to log out the user
    async function logoutUser() {
        try {
            const response = await fetch('http://localhost:3000/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenFromCookie()}`
                }
            });
            if (response.ok) {
                // Update user interface and remove token from cookie
                loginm_link.textContent = 'Login'
                loginBtn.textContent = 'Login';
                deleteTokenFromCookie(); // Delete token from cookie
                alert('Successfully logged out');
            } else {
                const errorData = await response.json();
                alert('Error: ' + errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error logging out');
        }
    }


    // Show login or logout form
    loginBtn.addEventListener("click", (e) => {
        e.preventDefault();
        console.log('Login button clicked'); // Provjera klikne li gumb
        // Check if user is logged in
        if (isLoggedIn()) {
            // If logged in, log out
            logoutUser();
        } else {
            // If not logged in, show login form
            formContainer.classList.remove("active");
            formContainer.classList.add("active-login");
        }
    });

    // Show mobile login or logout form
    loginm_link.addEventListener("click", (e) => {
        e.preventDefault();
        console.log('Login link clicked'); // Provjera klikne li gumb
        // Check if user is logged in
        if (isLoggedIn()) {
            // If logged in, log out
            logoutUser();
        } else {
            // If not logged in, show login form
            formContainer.classList.remove("active");
            formContainer.classList.add("active-login");
        }
    });



    // Submit reset password form
    resetPasswordForm.querySelector("form").addEventListener("submit", async function (event) {
        event.preventDefault();
        const token = new URLSearchParams(window.location.search).get('token');
        const newPassword = this.querySelectorAll('input[type="password"]')[0].value;
        const confirmPassword = this.querySelectorAll('input[type="password"]')[1].value;
        if (newPassword !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        try {
            const response = await fetch('http://localhost:3000/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token: token, newPassword: newPassword })
            });
            if (response.ok) {
                const data = await response.json();
                alert('Success: ' + data.message);
                // Redirect to login page or perform other appropriate action
            } else {
                const errorData = await response.json();
                alert('Error: ' + errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error resetting password');
        }
    });



    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
        return null; // Vraća null ako kolačić nije pronađen
    }
    


/*************WALLET CONNECT PART***************/
import Web3 from 'web3';

let isRequestingAccounts = false;
let walletConnected = false;

async function checkWalletConnection() {
    if (typeof window.ethereum === 'undefined') {
        return false;
    }

    const web3 = new Web3(window.ethereum);
    const accounts = await web3.eth.getAccounts();
    return accounts.length > 0; // Vraća true ako ima povezanih računa
}

async function connectWallet() {
    if (isRequestingAccounts) {
        console.log('MetaMask request already in progress');
        return;
    }

    isRequestingAccounts = true;

    try {
        if (typeof window.ethereum === 'undefined') {
            alert('MetaMask is not installed. Please install MetaMask to use this feature.');
            return;
        }

        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        
        if (accounts.length > 0) {
            alert('Connected account: ' + accounts[0]);
            walletConnected = true;
            const token = getCookiewallet('jwt');

            const response = await fetch('http://localhost:3000/api/connectWallet', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ userAddress: accounts[0] }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Failed to connect to the server:', errorData.message || 'Unknown error');
                alert('Failed to connect wallet: ' + (errorData.message || 'Unknown error occurred'));
                walletConnected = false;
                return;
            }

            const data = await response.json();
            if (data.success) {
                console.log('Wallet connected and data saved');
            } else {
                console.error('Error saving wallet data');
            }
        } else {
            walletConnected = false;
        }
    } catch (error) {
        console.error('User denied account access or there was an error', error);
    } finally {
        isRequestingAccounts = false;
    }
}

async function disconnectWallet() {
    try {
        console.log('Disconnecting wallet...');

        const token = getCookiewallet('jwt');
        const response = await fetch('http://localhost:3000/api/disconnectWallet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Failed to disconnect wallet:', errorData.message || 'Unknown error');
            alert('Failed to disconnect wallet: ' + (errorData.message || 'Unknown error occurred'));
            return;
        }

        const data = await response.json();
        if (data.success) {
            walletConnected = false;
            alert('Wallet disconnected successfully.');
        } else {
            console.error('Error disconnecting wallet on server');
        }
    } catch (error) {
        console.error('Error disconnecting wallet:', error);
    }
}

async function universalConnect() {
    try {
        const isWalletConnected = await checkWalletConnection(); // Provjera MetaMask konekcije

        if (isWalletConnected) {
            console.log('Wallet is already connected');
            alert('Wallet is already connected.');
        } else {
            await connectWallet(); // Samo poziva konekciju ako wallet nije povezan
        }
    } catch (error) {
        console.error('Error connecting/disconnecting wallet:', error);
    }
}

document.getElementById('connectWallet').addEventListener('click', universalConnect);

function getCookiewallet(name) {
    let cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        if (name == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
}


checkWalletConnection();

if (typeof window.ethereum !== 'undefined') {
    window.ethereum.on('accountsChanged', function (accounts) {
        walletConnected = accounts.length > 0;

        if (accounts.length === 0) {
            // Ako je lista računa prazna, pošaljite zahtjev za odspajanje na server
            const token = getCookiewallet('jwt');
            fetch('http://localhost:3000/api/disconnectWallet', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }).then(response => response.json())
              .then(data => {
                  if (data.success) {
                      console.log('Wallet disconnected on server');
                  }
              }).catch(error => {
                  console.error('Error disconnecting wallet:', error);
              });
        }
    });
}



/*********CONNECT WALLET MOBILE START************/
document.getElementById('connectwalletm_link').addEventListener('click', async function () {
    // Provjera širine zaslona za mobilne uređaje (max-width: 430px)
    if (window.innerWidth <= 430) {
        const deeplink = "metamask://";
        const appStoreLink = "https://apps.apple.com/app/metamask/id1438144202";
        const playStoreLink = "https://play.google.com/store/apps/details?id=io.metamask";

        // Provjera platforme
        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        const isAndroid = /Android/i.test(navigator.userAgent);

        // Spriječite višestruke klikove
        const connectButton = document.getElementById('connectwalletm_link');
        connectButton.disabled = true;

        // Pokušaj otvaranja MetaMask aplikacije putem deeplinka
        try {
            window.location.href = deeplink;

            // Ako aplikacija nije instalirana, preusmjeriti korisnika
            setTimeout(() => {
                if (isIOS) {
                    window.location.href = appStoreLink;
                } else if (isAndroid) {
                    window.location.href = playStoreLink;
                } else {
                    alert("MetaMask aplikacija nije podržana na vašem uređaju.");
                }
            }, 3000); // 3 sekunde čekanja
        } catch (error) {
            console.error("Greška prilikom otvaranja MetaMask aplikacije:", error);
            alert("Došlo je do pogreške. Provjerite jeste li instalirali MetaMask aplikaciju.");
        } finally {
            // Ponovno omogućite gumb nakon pokušaja
            connectButton.disabled = false;
        }
    } else {
        // Na širem zaslonu (desktop), povezivanje s MetaMask ekstenzijom
        connectWalletm();
    }
});

// Povezivanje s walletom koristeći Web3.js
async function connectWalletm() {
    if (typeof window.ethereum !== 'undefined') {
        try {
            // Spriječite višestruke klikove
            const connectButton = document.getElementById('connectwalletm_link');
            connectButton.disabled = true;

            // Provjera mreže (Ethereum Mainnet)
            const networkId = await window.ethereum.request({ method: 'net_version' });
            if (networkId !== "1") {
                alert("Molimo povežite se na Ethereum Mainnet mrežu.");
                return;
            }

            // Traženje pristupa MetaMasku
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const userAddress = accounts[0];
            console.log("Povezan MetaMask Wallet:", userAddress);

            // Dodavanje logike za spremanje adrese na backend
            await fetch('http://localhost:3000/api/connectWalletm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`, // Pretpostavlja autentikaciju tokenom
                },
                body: JSON.stringify({ walletAddress: userAddress }),
            });

            alert("Wallet uspješno povezan!");
        } catch (error) {
            console.error("Povezivanje s MetaMaskom nije uspjelo:", error);
            alert("Povezivanje nije uspjelo. Pokušajte ponovo.");
        } finally {
            // Ponovno omogućite gumb
            const connectButton = document.getElementById('connectwalletm_link');
            connectButton.disabled = false;
        }
    } else {
        alert("MetaMask nije instaliran. Molimo instalirajte ekstenziju.");
    }
}
/*********CONNECT WALLET MOBILE END**************/
  
//-----------------COUNTER SECTION--------------//

let currentIndex = 0;  // Za praćenje trenutačnog indeksa za prikazivanje adresa
let totalCollected = 0;
let timer;
let timerRunning = false;
const baseSpeed = 30000; // Osnovna brzina od 30 sekundi
let rotationInterval;
let addressesAdded = [];
let finalAddress = ''; // Varijabla za čuvanje finalne adrese

const COINGECKO_API_URL = 'http://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd';

async function getEthPrice() {
    try {
        const response = await fetch(COINGECKO_API_URL);
        if (!response.ok) {
            throw new Error('Failed to fetch ETH price');
        }
        const data = await response.json();
        return data.ethereum.usd;
    } catch (error) {
        console.error('Error fetching ETH price:', error);
        return null;
    }
}

async function convertUsdToEth(usdAmount) {
    const ethPrice = await getEthPrice();
    if (ethPrice === null) {
        throw new Error('Failed to convert USD to ETH due to missing ETH price');
    }
    return usdAmount / ethPrice;
}

function calculateSpeed() {
    return baseSpeed / Math.exp(addressesAdded.length / 100); // Eksponencijalni rast brzine
}




async function handlePayment(usdAmount) {
    const token = getCookie('jwt'); // Provjerite funkciju getCookie
    console.log('handlePayment called with:', usdAmount);

    if (typeof window.ethereum === 'undefined') {
        await showModal('Please install and connect MetaMask!');
        return;
    }

    const web3 = new Web3(window.ethereum);

    try {
        // Dohvaćanje trenutne mreže odabrane u MetaMasku
        const currentNetwork = await getCurrentNetwork();
        const selectedNetwork = Object.values(NETWORKS).find(
            (network) => network.chainId === currentNetwork
        );

        if (!selectedNetwork) {
            await showModal('The selected network is not supported. Please choose a supported network in MetaMask.');
            console.error('Unsupported network:', currentNetwork);
            return;
        }

        console.log(`Using selected network: ${selectedNetwork.chainName} (${selectedNetwork.chainId})`);

        console.log('Requesting MetaMask accounts...');
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        console.log('Accounts:', accounts);

        const ethAmount = await convertUsdToEth(usdAmount);
        console.log('ETH Amount:', ethAmount);

        const ethAmountWei = web3.utils.toWei(ethAmount.toString(), 'ether');
        console.log('ETH Amount in Wei:', ethAmountWei);

        const balanceWei = await web3.eth.getBalance(accounts[0]);
        console.log('Wallet Balance in Wei:', balanceWei);

        const baseFeePerGas = await web3.eth.getGasPrice();
        const maxPriorityFeePerGas = web3.utils.toWei('2', 'gwei');
        const maxFeePerGas = BigInt(baseFeePerGas) + BigInt(maxPriorityFeePerGas);

        console.log('Base Fee Per Gas:', baseFeePerGas);
        console.log('Max Priority Fee Per Gas:', maxPriorityFeePerGas);
        console.log('Max Fee Per Gas:', maxFeePerGas.toString());

        const defaultGasLimit = 30000; // Postavite minimalni gas limit

        const estimatedGasLimit = await web3.eth.estimateGas({
            from: accounts[0],
            to: '0xEAb7Be63c58DC0D6C181aBEf0785F74409097422', // Adresa primatelja
            value: ethAmountWei,
        }).catch(async (err) => {
            console.error('Gas estimation failed:', err);
            await showModal('Gas estimation failed: ' + err.message);
            return defaultGasLimit; // Koristite defaultGasLimit kao rezervnu vrijednost
        });

        const totalCostWei =
            BigInt(ethAmountWei) + BigInt(estimatedGasLimit) * maxFeePerGas;
        console.log('Total Cost in Wei (ETH + gas):', totalCostWei);

        if (BigInt(balanceWei) < totalCostWei) {
            await showModal(
                `Insufficient funds. Your balance is ${web3.utils.fromWei(
                    balanceWei,
                    'ether'
                )} ETH, but you need at least ${web3.utils.fromWei(
                    totalCostWei.toString(),
                    'ether'
                )} ETH to cover the transaction.`
            );
            return;
        }

        const userConfirmed = await showModal(`Do you want to proceed with the payment of ${ethAmount} ETH?`);
        if (!userConfirmed) {
            console.log('User cancelled the transaction.');
            return;
        }

        console.log('Sending transaction...');
        const transaction = await web3.eth.sendTransaction({
            from: accounts[0],
            to: '0xEAb7Be63c58DC0D6C181aBEf0785F74409097422', // Adresa primatelja
            value: ethAmountWei,
            gas: estimatedGasLimit,
            maxFeePerGas: maxFeePerGas.toString(),
            maxPriorityFeePerGas: maxPriorityFeePerGas,
        });

        console.log('Transaction receipt:', transaction);

        // Čekanje na potvrdu transakcije
        const receipt = await waitForTransactionConfirmation(web3, transaction.transactionHash);

        if (receipt.status) {
            await showModal('Payment successful! Transaction hash: ' + transaction.transactionHash);

            // Slanje transakcije na backend samo ako je potvrđena
            console.log('Sending request to /api/saveTransaction:', {
                walletAddress: accounts[0],
                paymentValue: usdAmount,
            });

            try {
                const response = await fetch('http://localhost:3000/api/saveTransaction', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        walletAddress: accounts[0],
                        paymentValue: usdAmount,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                if (data.success) {
                    console.log('Transaction saved successfully');
                    window.location.reload();
                } else {
                    console.error('Error saving transaction:', data.message);
                }
            } catch (error) {
                console.error('Error making saveTransaction request:', error);
            }
        } else {
            await showModal('Transaction failed to confirm. Please try again.');
            console.error('Transaction failed:', receipt);
        }

    } catch (error) {
        console.error('Payment error:', error);
        await showModal(`Payment failed: ${error.message}. Full error: ${JSON.stringify(error)}`);
    }
}


// Funkcija za čekanje potvrde transakcije
async function waitForTransactionConfirmation(web3, transactionHash) {
    return new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
            const receipt = await web3.eth.getTransactionReceipt(transactionHash);
            if (receipt) {
                clearInterval(interval);
                resolve(receipt);
            }
        }, 1000); // Provjerava svake sekunde
    });
}


// Funkcija za dohvaćanje trenutne mreže
async function getCurrentNetwork() {
    if (typeof window.ethereum === 'undefined') {
        console.error('MetaMask is not installed');
        return null;
    }
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    console.log('Current Network Chain ID:', chainId);
    return parseInt(chainId, 16); // Pretvaranje iz hex u decimalni format
}

// Definicija podržanih mreža
const NETWORKS = {
    ethereumMainnet: {
        chainId: 1,
        chainName: 'Ethereum Mainnet',
    },
    arbitrumOne: {
        chainId: 42161,
        chainName: 'Arbitrum One',
    },
    // Dodajte druge mreže po potrebi
};




document.addEventListener('DOMContentLoaded', () => {
    const ticketButtons = document.querySelectorAll('.ticket-button');

    if (!isLoggedIn()) {
        console.log('User is NOT logged in. Disabling buttons...');
        ticketButtons.forEach(button => {
            button.disabled = true;
            button.classList.add('disabled-button');
        });
    }

    // Dodaj event listenere za klik na svaki ticket gumb
    ticketButtons.forEach(button => {
        button.addEventListener('click', async (event) => {
            const usdValue = parseInt(event.target.getAttribute('data-value'));
            console.log('Button clicked with value:', usdValue);
            await handlePayment(usdValue);
        });
    });
});



document.addEventListener('DOMContentLoaded', () => {
    let savedData = null;
    try {
        savedData = JSON.parse(localStorage.getItem('lastTimerData'));
    } catch (error) {
        console.error('Error parsing lastTimerData from localStorage:', error);
    }

    const savedAddress = localStorage.getItem('lastWinnerAddress');
    const cryptoAddressElement = document.getElementById('crypto-address');
    const timerElement = document.getElementById('timer');
    const totalCollectedElement = document.getElementById('total-collected');

    if (savedData) {
        const remainingTime = savedData.remainingTime || defaultTimerSettings.time;

        // Proverite i konvertujte totalCollected
        const totalCollected = parseFloat(savedData.totalCollected) || 0;

        // Ažurirajte preostalo vreme
        if (timerElement) {
            timerElement.textContent = formatTime(remainingTime);
        } else {
            console.error('Timer element not found');
        }

        // Ažurirajte ukupno prikupljeni iznos
        if (totalCollectedElement) {
            totalCollectedElement.textContent = `Total Collected: $${totalCollected.toFixed(2)}`;
        } else {
            console.error('Total collected element not found');
        }

        // Prikažite pobedničku adresu ako je vreme isteklo
        if (remainingTime === 0 && cryptoAddressElement) {
            cryptoAddressElement.textContent = savedAddress || 'No Winner Yet';
        }
    } else {
        console.warn('No saved timer data found in localStorage');
    }
});



/////////////// SOCKET PART START ///////////////
//process.env.SOCKET_URL || 
// Inicijalizacija Socket veze
const socket = io('http://localhost:3000', {
    transports: ['websocket'], // ili ['polling', 'websocket']
    withCredentials: true,
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 2000, // 2 sekunde između pokušaja
});

console.log('Initializing socket with URL:', process.env.SOCKET_URL || 'http://localhost:3000');
console.log('Socket instance:', socket);

localStorage.debug = "*";

let countdownInterval;

// Funkcija za formatiranje vremena
function formatTime(seconds) {
    const h = String(Math.floor(seconds / 3600)).padStart(2, '0');
    const m = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    const s = String(seconds % 60).padStart(2, '0');
    return `${h}:${m}:${s}`;
}


// Funkcija za spremanje win addrese u db
async function savetowinnertable(winaddress, amountWon, dateWon) {
    if (!winaddress || !amountWon || !dateWon) {
        console.error('Invalid data:', { winaddress, amountWon, dateWon });
        return;
    }

    try {
        const response = await fetch('http://localhost:3000/api/savetowinnertable', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                winaddress: String(winaddress),
                amountWon: String(amountWon),
                dateWon: String(dateWon),
            }),
        });

        const data = await response.json();
        if (response.ok) {
            console.log('Winner saved successfully:', data);
        } else {
            console.error('Error saving winner:', data.message);
        }
    } catch (error) {
        console.error('Error saving winner:', error);
    }
}

const defaultTimerSettings = {
    time: 300,
    totalCollected: 'Total Collected: $0',
    addressPlaceholder: 'Addresses Will Appear Here!',
};

// WebSocket listener za 'timerUpdate'
let lastTimerData = { remainingTime: null, totalCollected: null, randomAddress: null };


socket.on('timerUpdate', (data) => {
    const { remainingTime, totalCollected, randomAddress } = data;

    // Ažuriraj UI i localStorage
    if (remainingTime !== lastTimerData.remainingTime /*|| totalCollected !== lastTimerData.totalCollected*/) {
        console.log('New data received:', data);

        updateUI({ remainingTime, totalCollected, randomAddress });

        // Spremi podatke u localStorage
        localStorage.setItem(
            'lastTimerData',
            JSON.stringify({ remainingTime, totalCollected, randomAddress })
        );

        // Ažuriraj lokalno stanje
        lastTimerData = { remainingTime, totalCollected, randomAddress };
    } else {
        console.log('Duplicate data ignored.');
    }
});


// Dohvati početno stanje timera iz servera
fetch('http://localhost:3000/api/getActiveTimer')
    .then((response) => {
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        return response.json();
    })
    .then((data) => {
        if (data.success) {
            //startCountdown(data.timer.remainingTime);
            updateUI(data.timer);
        } else {
            console.warn('No active timer found:', data.message);
        }
    })
    .catch((error) => {
        console.error('Error fetching timer data:', error);
       // alert('Error fetching timer data. Please try again later.');
    });

    
    window.addEventListener('load', () => {
        const lastTimerData = localStorage.getItem('lastTimerData');
        if (lastTimerData) {
            updateUI(JSON.parse(lastTimerData));
        }
    });///zakomentirano radi provjere

    function saveWinnerToTimer(addressWin) { 
    // Debugging: provjera podataka koji se šalju
    console.log('Data being sent to backend:', { addressWin });

    // Validacija adrese prije slanja
    if (!addressWin || typeof addressWin !== 'string' || !addressWin.startsWith('0x')) {
        console.error('Invalid addressWin:', addressWin);
        return Promise.reject('Invalid address');
    }

    return fetch('http://localhost:3000/api/update-timer-winner', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ addressWin }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error('Failed to update Timer with winner address');
        }
        return response.json();
    });
}



function addWinnerToTable(winAddress, amountWon, dateWon) {
    // Pretvori niz adresa u string ako je potrebno
    if (Array.isArray(winAddress)) {
        winAddress = winAddress.join(''); // Pretvori niz u string
    }

    const winnersTableBody = document.getElementById('winners-table-body');

    // Provjera postoji li već zapis s istom adresom i datumom
    const existingEntries = Array.from(winnersTableBody.querySelectorAll('tr')).filter((row) => {
        const cells = row.querySelectorAll('td');
        return cells[1]?.textContent === winAddress && cells[3]?.textContent === dateWon;
    });

    if (existingEntries.length > 0) {
        console.warn('Winner already exists in the table:', winAddress, dateWon);
        return; // Izlazak ako zapis već postoji
    }

    // Dodavanje novog unosa u tablicu
    const newRow = document.createElement('tr');
    newRow.innerHTML = `
        <td>${winnersTableBody.rows.length + 1}</td>
        <td>${winAddress}</td>
        <td>$${amountWon.toFixed(2)}</td>
        <td>${dateWon}</td>
    `;
    winnersTableBody.appendChild(newRow);

    // Pozovi API za spremanje pobjednika u bazu
    savetowinnertable(winAddress, amountWon, dateWon);

    saveWinnerToTimer(winAddress)
        .then(() => console.log('Winner saved successfully'))
        .catch((error) => {
            console.error('Error saving winner to database:', error);
            // Moguće obavijestiti korisnika ili omogućiti ponovno pokušavanje
        });
}


// Prijem podataka od servera za winnertable
socket.on('updateWinnersTable', (winners) => {
    console.log('Primljeni podaci pobjednika:', winners); // Provjera podataka
    const winnersTableBody = document.getElementById('winners-table-body');

    winnersTableBody.innerHTML = ''; // Čišćenje prije dodavanja novih podataka

    winners.forEach((winner, index) => {
        const newRow = document.createElement('tr');
        newRow.innerHTML = `
            <td>${index + 1}</td>
            <td>${winner.winaddress}</td>
            <td>$${parseFloat(winner.amountWon).toFixed(2)}</td>
            <td>${winner.dateWon}</td>
        `;
        winnersTableBody.appendChild(newRow);
    });
});



function updateUI({ remainingTime, totalCollected, randomAddress }) {
    const defaultTimerSettings = {
        time: 300,
        totalCollected: 'Total Collected: $0',
        addressPlaceholder: 'Addresses Will Appear Here!',
    };

    const totalCollectedElement = document.getElementById('total-collected');
    if (totalCollectedElement) {
        const currentDisplayedValue = totalCollectedElement.textContent;
        const newValue = `Total Collected: $${totalCollected.toFixed(2)}`;

        if (currentDisplayedValue !== newValue) {
            totalCollectedElement.textContent = newValue;
        }
    } else {
        console.error('Total collected element not found');
    }

    localStorage.setItem('lastTimerData', JSON.stringify({ remainingTime, totalCollected, randomAddress }));

    const timerElement = document.getElementById('timer');
    if (timerElement) {
        timerElement.textContent = formatTime(remainingTime);
    } else {
        console.error('Timer element not found');
    }

    if (remainingTime === 0) {
        if (window.addressInterval) {
            clearInterval(window.addressInterval);
            window.addressInterval = null;
        }

        // Dohvati zadnju prikazanu adresu iz localStorage-a
        const lastDisplayedAddress = localStorage.getItem('lastDisplayedAddress') || 'Unknown Address';
        const amountWon = totalCollected;
        const dateWon = new Date().toLocaleString();

        if (lastDisplayedAddress && lastDisplayedAddress !== defaultTimerSettings.addressPlaceholder) {
            const winnersTableBody = document.getElementById('winners-table-body');
            const alreadyExists = Array.from(winnersTableBody.querySelectorAll('tr')).some((row) => {
                const cells = row.querySelectorAll('td');
                return cells[1]?.textContent === lastDisplayedAddress && cells[3]?.textContent === dateWon;
            });

            if (!alreadyExists) {
                addWinnerToTable(lastDisplayedAddress, amountWon, dateWon);
                localStorage.setItem('lastWinnerAddress', lastDisplayedAddress);
            } else {
                console.warn('Duplicate winner prevented:', lastDisplayedAddress, dateWon);
            }
        }

        setTimeout(() => {
            resetUI(defaultTimerSettings);
            localStorage.removeItem('lastTimerData');
            localStorage.removeItem('lastWinnerAddress');
        }, 5 * 60 * 1000);

        return;
    }

    const addressElement = document.getElementById('crypto-address');
    if (addressElement && Array.isArray(randomAddress) && randomAddress.length > 0) {
        const spanElement = addressElement.querySelector("span");
        if (!spanElement) {
            console.error('Span element not found inside #crypto-address');
            return;
        }

        if (!window.addressInterval) {
            let currentIndex = 0;

            const showNextAddress = () => {
                addressElement.classList.add('fade-out');
                setTimeout(() => {
                    const nextAddress = randomAddress[currentIndex];
                    spanElement.textContent = nextAddress;

                    // Spremi zadnju prikazanu adresu
                    localStorage.setItem('lastDisplayedAddress', nextAddress);

                    currentIndex = (currentIndex + 1) % randomAddress.length;
                    addressElement.classList.remove('fade-out');
                    addressElement.classList.add('fade-in');
                    setTimeout(() => addressElement.classList.remove('fade-in'), 500);
                }, 500);
            };

            window.addressInterval = setInterval(showNextAddress, 1000);
            showNextAddress();
        }
    }
    console.log('UI updated - Total Collected:', totalCollectedElement?.textContent);
}




// Funkcija za resetiranje UI-a na početne vrijednosti
function resetUI(defaultSettings) {
    const timerElement = document.getElementById('timer');
    const totalCollectedElement = document.getElementById('total-collected');
    const addressElement = document.getElementById('crypto-address');

    if (timerElement) {
        timerElement.textContent = formatTime(defaultSettings.time);
    }
    if (totalCollectedElement) {
        totalCollectedElement.textContent = defaultSettings.totalCollected;
    }
    if (addressElement) {
        const spanElement = addressElement.querySelector("span");
        if (spanElement) {
            spanElement.textContent = defaultSettings.addressPlaceholder;
        }
    }
    console.log('UI reset to default values');
}



socket.on('disconnect', () => {
    console.warn('Disconnected from server.');
    const statusElement = document.getElementById('status');
    if (statusElement) {
        statusElement.textContent = 'Disconnected from server. Reconnecting...';
        statusElement.style.color = 'red';
    }
});


socket.on('connect', () => {
    console.log('Reconnected to server.');
    const statusElement = document.getElementById('status');
    if (statusElement) {
        statusElement.textContent = 'Connected to server.';
        statusElement.style.color = 'green';
    }
    // Nema potrebe za fetch-om, čekaj emitovane podatke preko socket-a
});

let pollingActive = true;

// Pauziraj polling dok je WebSocket veza aktivna
socket.on('connect', () => {
    console.log('Connected to server. Stopping polling.');
    pollingActive = false;
    const statusElement = document.getElementById('status');
    if (statusElement) {
        statusElement.textContent = 'Connected to server.';
        statusElement.style.color = 'green';
    }
});

// Ponovo pokreni polling ako veza s WebSocketom nestane
socket.on('disconnect', () => {
    console.warn('Disconnected from server. Starting polling.');
    pollingActive = true;
    const statusElement = document.getElementById('status');
    if (statusElement) {
        statusElement.textContent = 'Disconnected from server. Using polling.';
        statusElement.style.color = 'orange';
    }
});

// Polling funkcija
setInterval(() => {
    if (pollingActive) {
        fetch('http://localhost:3000/api/getActiveTimer')
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    updateUI(data.timer);
                    const statusElement = document.getElementById('status');
                    if (statusElement) {
                        statusElement.textContent = 'Polling active. Data updated.';
                        statusElement.style.color = 'blue';
                    }
                }
            })
            .catch((error) => {
                console.error('Error fetching timer data:', error);
                const statusElement = document.getElementById('status');
                if (statusElement) {
                    statusElement.textContent = 'Error fetching data. Retrying...';
                    statusElement.style.color = 'red';
                }
            });
    }
}, 10000); // Svakih 10 sekundi



socket.on('reconnect_failed', () => {
    console.error('Reconnection failed. Check your server.');
});
console.log('SOCKET_URL:', process.env.SOCKET_URL);










//*********************************PRIKAZ SEKCIJA*********************************/



document.addEventListener('DOMContentLoaded', function() {
    // WINNERS SEKCIJA
    const winnersNavItem = document.getElementById('winnersNavItem');
    const winnersSection = document.getElementById('winners-section');

    winnersNavItem.addEventListener('click', function(e) {
        e.preventDefault();
        winnersSection.style.display = 'block';
        winnersSection.scrollIntoView({ behavior: 'smooth' });
    });

    // CONTACT SEKCIJA
    const contactNavItem = document.getElementById('contactNavItem');
    const contactSection = document.getElementById('contact-section');

    contactNavItem.addEventListener('click', function(e) {
        e.preventDefault();
        contactSection.style.display = 'block';
        contactSection.scrollIntoView({ behavior: 'smooth' });
    });

    // ABOUT SEKCIJA
    const aboutNavItem = document.getElementById('aboutNavItem');
    const aboutSection = document.getElementById('about-section');

    aboutNavItem.addEventListener('click', function(e) {
        e.preventDefault();
        aboutSection.style.display = 'block';
        aboutSection.scrollIntoView({ behavior: 'smooth' });
    });
});


//------------BANNER SECTION START----------//

document.addEventListener('DOMContentLoaded', function() {
    const bannerSlider = document.querySelector('.banner-slider');

    if (!bannerSlider.classList.contains('duplicated')) { 
        bannerSlider.innerHTML += bannerSlider.innerHTML;
        bannerSlider.classList.add('duplicated');
    }

    const banners = document.querySelectorAll('.banner');
    const bannerWidth = banners[0].offsetWidth; // Pretpostavlja se da su svi banneri iste širine
     // Dodaj console.log ovde da proveriš vrednost bannerWidth
     console.log('Banner width:', bannerWidth);

      // Izračunaj broj banner-a u slideru nakon dupliranja
    const totalBanners = banners.length; // Ovo je broj banner-a u dupliranom slideru

    // Izračunaj širinu slidera
    const sliderWidth = totalBanners * bannerWidth;
    bannerSlider.style.width = `${sliderWidth/4}px`;

    // Debugging
      console.log('Banner width:', bannerWidth);
      console.log('Slider width:', sliderWidth);

    let animationDuration = 15; // Adjust to control speed of animation


    // Start continuous animation
    bannerSlider.style.animation = `slider ${animationDuration}s infinite linear`;

    // Hover Effect using JavaScript to pause animation
    bannerSlider.addEventListener('mouseover', function() {
        bannerSlider.style.animationPlayState = 'paused';
    });

    bannerSlider.addEventListener('mouseout', function() {
        bannerSlider.style.animationPlayState = 'running';
    });
});


//********TABLE RESIZING START***********//
window.addEventListener('resize', updateColumnHeaders);

function updateColumnHeaders() {
  const tableHeaders = document.querySelectorAll('th');
  
  if (window.innerWidth <= 430) {
    tableHeaders[0].textContent = 'RN';  // Promjena naziva za "Row Number"
    tableHeaders[1].textContent = 'Crypto Adress';  // Promjena naziva za "Crypto Wallet Address"
    tableHeaders[2].textContent = 'Amount';  // Promjena naziva za "Amount Won"
    tableHeaders[3].textContent = 'Date';  // Promjena naziva za "Date Won"
  } else {
    tableHeaders[0].textContent = 'Row Number';  // Povratak na originalne nazive
    tableHeaders[1].textContent = 'Crypto Wallet Address';  // Povratak na originalne nazive
    tableHeaders[2].textContent = 'Amount Won';  // Povratak na originalne nazive
    tableHeaders[3].textContent = 'Date Won';  // Povratak na originalne nazive
  }
}

// Pozivanje funkcije odmah kako bi se osigurao ispravan naziv prilikom učitavanja stranice
updateColumnHeaders();

//********TABLE RESIZING END***********//
